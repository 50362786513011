import React from 'react';
import { Spin } from 'antd';
import cx from 'classnames';
import style from './contacts.module.scss';
import { Layouts } from '../components/components';
import { StrapiMainPagesNames, StrapiBlocksName } from '../lib/constants/strapi';
import { replaceStrapiComponentName, chunkArray } from '../lib/utils';
import { useStrapiPage } from '../lib/hooks/useStrapiPage';

const ContactsPage: React.FC = () => {
  const { IndexLayout, Container, LayoutBG } = Layouts;
  const { currentPage } = useStrapiPage(StrapiMainPagesNames.contacts);
  return (
    <IndexLayout>
      {currentPage && currentPage.blocks ? (
        <LayoutBG
          background={`url(${process.env.STRAPI_API_URL}/uploads/study_bg_9717ec1a57.png) center top / cover no-repeat`}
          className={style.contactsWrapper}
        >
          <Container className={style.contactsContainer}>
            <h2 className={style.contactsTitle}>Контакты</h2>
            <div className={style.flexWrapper}>
              {currentPage.blocks.map((block, index) => {
                switch (replaceStrapiComponentName(block?.__component)) {
                  case StrapiBlocksName.contactsList:
                    return (
                      <React.Fragment key={index}>
                        {block &&
                          chunkArray(block.contactItems, 4).map((array, index) => (
                            <div className={style.contactsList} key={index}>
                              {array.map((contact, idx) => {
                                return (
                                  <div className={style.contact} key={idx}>
                                    <a
                                      target="_blank"
                                      href={contact.type === 'mail' ? `mailto:${contact.link}` : contact.link}
                                      className={cx(style.icon, contact?.type && style[contact?.type])}
                                    />
                                    <div className={style.info}>
                                      <div className={style.label}>{contact?.label}</div>
                                      <div className={style.info}>{contact?.contact}</div>
                                      {contact?.secondLabel && contact?.secondContact && (
                                        <div className={style.additionalInfo}>
                                          <div className={style.label}>{contact?.secondLabel}</div>
                                          <div className={style.info}>{contact?.secondContact}</div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ))}
                      </React.Fragment>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </Container>
          <div className={style.phoneWrapper}>
            <div className={style.phone} />
          </div>
        </LayoutBG>
      ) : (
        <div className={style.loader}>
          <Spin />
        </div>
      )}
    </IndexLayout>
  );
};

export default ContactsPage;
